.screen-saver {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #ba9191;
}

.pointer-events-none {
  pointer-events: none;
}

.display-none {
  display: none;
}
